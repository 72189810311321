<template>
  <div class="wrapper about">
    <h1>More coming soon...</h1>
  </div>
</template>

<style lang="scss" scoped>
.about {
  margin-top: 200px;
  text-align: center;
  font-size: 10px;
}
</style>
